

.OptionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.aButtonLeft {
    width: 200px;
    margin-right: 20%;
}

.aButtonRight {
    width: 200px;
    margin-left: 20%;
}

.CreateProjectButton {
    width: 200px;
    margin-right: auto;
}