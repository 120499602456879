.ConfigSetup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.ConfigBox {
  width: 70%;
}

.InputPair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
