
.group1 {
    stroke: cornflowerblue !important;
    stroke-width: 3;
}

.group2 {
    stroke: indianred !important;
    stroke-width: 3;
}
