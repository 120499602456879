
.GeneralConfig {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GeneralConfig > * {
    white-space: nowrap;
}

.InputPair {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GeneInputs {
    display: flex;
    flex-direction: row;
    align-items: center;
}